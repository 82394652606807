<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <notifications></notifications>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--9 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>{{ $t("COMMON.RESET_PASSWORD") }}</small>
              </div>

              <base-alert :dismissible="true" type="danger" v-if="errors.token">
                {{ $t("COMMON.TOKEN_ERROR") }}
              </base-alert>

              <base-alert :dismissible="true" type="danger" v-if="errors.email">
                {{ $t("COMMON.EMAIL_ERROR") }}
              </base-alert>

              <base-alert type="success" v-if="passwordChanged">
                {{ $t("COMMON.PASSWORD_UPDATED") }}
                <router-link :to="{ name: 'Login' }" class="text-light">
                  <small>{{ $t("COMMON.RETURN_TO_LOGIN") }}</small>
                </router-link>
              </base-alert>

              <form
                class="needs-validation"
                @submit.prevent="handleSubmit()"
                v-if="!passwordChanged"
              >
                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-lock-circle-open"
                  :placeholder="$t('COMMON.PASSWORD')"
                  type="password"
                  name="Password"
                  v-model="form.data.attributes.password"
                >
                </base-input>
                <validation-error :errors="errors.password" />

                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-lock-circle-open"
                  :placeholder="$t('COMMON.CONFIRM_PASSWORD')"
                  type="password"
                  name="Password confirmation"
                  v-model="form.data.attributes.password_confirmation"
                >
                </base-input>
                <validation-error :errors="errors.password_confirmation" />

                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-4">
                    {{ $t("COMMON.RESET_PASSWORD") }}
                  </base-button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link :to="{ name: 'Login' }" class="text-light">
                <small>{{ $t("COMMON.RETURN_TO_LOGIN") }}</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseAlert } from "@/components";
import ValidationError from "@/components/ValidationError.vue";

export default {
  layout: "AuthLayout",
  mixins: [],
  components: { ValidationError, BaseAlert },
  data() {
    return {
      form: {
        data: {
          type: "password-reset",
          attributes: {
            password: "",
            password_confirmation: "",
            token: "",
            email: "",
          },
        },
      },
      passwordChanged: false,
      errors: {},
    };
  },
  mounted() {
    this.form.data.attributes.email = this.$route.query.email;
    this.form.data.attributes.token = this.$route.query.token;
  },
  beforeDestroy() {
    this.$router.replace({ query: null });
  },
  methods: {
    async handleSubmit() {
      this.errors = {};
      try {
        await this.$store.dispatch("reset/createNewPassword", this.form.data);
        this.passwordChanged = true;
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
  },
};
</script>
